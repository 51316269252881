.App {
  text-align: center;
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-weight: <weight>;
  font-style: normal;
  font-variation-settings: "slnt" 0;
}

.App-logo {
  height: 60px;
  pointer-events: none;
  margin-top: 150px;
}

.App-header {
  padding: 0px 20px;
  box-sizing: border-box;
  background-color: white;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgba(0, 0, 0, 0.87);
  
}

.App-link {
  color: #61dafb;
}

.countdownWrap {
  max-width: 840px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
  width: 100%;
}

.countdownCol {
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.countdownColLabel {
  width: 115px;
  position: absolute;
  top: 40px;
  font-weight: 500;
  color: #7D7D7D;
  opacity: 0.87;
  margin-top: 10px;
  font-size: 24px;
}

.countdownColValue {
  color: #2A2A2A;
  opacity: 0.87;
  font-weight: 700;
  font-size: 30px;
}

.statement {
  max-width: 600px;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 100px;
  white-space: pre-line;
  opacity: 0.87;
  line-height: 59px;
}

@media (max-width: 800px) {
  .App-logo {
    height: 42px;
  }
  .countdownCol {
  }
  .countdownColLabel {
    top: 20px;
    font-size: 15px;
  }
  .countdownColValue {
    font-size: 22px;
  }
  .statement {
    font-size: 30px;
    line-height: 40px;
  }
}
